import { createWebHistory, createRouter } from 'vue-router'
import CourtOps from '@/pages/CourtOps.vue'
import CourtOpsInt from '@/pages/CourtOpsInternal.vue'
import Generic from '@/pages/Generic.vue'
import MethCheck from '@/pages/MethCheck.vue'
import OCV from '@/pages/OCV.vue'
import RiskIntelligence from '@/pages/RiskIntelligence.vue'
import Vine from '@/pages/Vine.vue'
import VineRestore from '@/pages/VineRestore.vue'
import ProviderSafe from '@/pages/ProviderSafe.vue'
import AlertXpress from '@/pages/AlertXpress.vue'
import JusticeXchange from '@/pages/JusticeXchange.vue'
import VPO from '@/pages/VPO.vue'
import SmartScreen from '@/pages/SmartScreen.vue'
import Home from '@/pages/Home.vue'
import PageNotFound from '@/pages/PageNotFound.vue'


const routes = [
	{ 
		name: 'Ops Desk',
		path: '/', 
		component: Home,
		meta: {
			title: 'Ops Desk'
		}
	},
	{
		name: 'CourtOps',
		path: '/courtops',
		component: CourtOps,
		meta: {
			title: 'CourtOps'
		}
	},
	{
		name: 'CourtOps Internal',
		path: '/courtopsinternal',
		component: CourtOpsInt,
		meta: {
			title: 'CourtOps Internal'
		}
	},
	{ 
		name: 'OCV',
		path: '/ocv', 
		component: OCV,
		meta: {
			title: 'OCV'
		}
	},
	{
		name: 'Generic Request',
		path: '/generic', 
		component: Generic,
		meta: {
			title: 'Generic Request'
		}
	},
    { 
		name: 'Meth Check',
		path: '/methcheck', 
		component: MethCheck,
		meta: {
			title: 'Meth Check'
		}
	},
    { 
		name: 'RiskIntelligence',
		path: '/intelligence',
		component: RiskIntelligence,
		meta: {
			title: 'RiskIntelligence'
		}
	},
	{
		path: '/riskintelligence',
		redirect: '/intelligence',
	},
    { 
		name: 'Vine',
		path: '/vine', 
		component: Vine,
		meta: {
			title: 'Vine'
		}
	},
	{ 
		name: 'Vine Restore',
		path: '/vinerestore', 
		component: VineRestore,
		meta: {
			title: 'Vine'
		}
	},
	{
		name: 'ProviderSafe',
		path: '/providersafe',
		component: ProviderSafe,
		meta: {
			title: 'ProviderSafe'
		}
	},
	{
		name: 'AlertXpress',
		path: '/alertxpress',
		component: AlertXpress,
		meta: {
			title: 'AlertXpress'
		}
	},
	{
		name: 'JusticeXchange',
		path: '/justicexchange',
		component: JusticeXchange,
		meta: {
			title: 'JusticeXchange'
		}
	},
	{
		name: 'VPO',
		path: '/vpo',
		component: VPO,
		meta: {
			title: 'VPO'
		}
	},
	{
		name: 'Smart Screen',
		path: '/smartscreen',
		component: SmartScreen,
		meta: {
			title: 'Smart Screen'
		}
	},
	{
		name: '404',
		path: '/:pathMatch(.*)*',
		component: PageNotFound,
		meta: {
			title: '404'
		}
	} // 404 must be at the end of the list
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
