<template>
    <div id="app" class="app-body"><router-view></router-view></div>
</template>

<script lang="ts" setup>
</script>

<style>
.app-body { 
    display:flex; 
    flex-direction:column; 
    margin:0;
    min-height: 100vh;
}
</style>