<template>
<footer class="content-footer bg-light b-t">
    <div class="d-xl-flex flex align-items-center pl-15 pr-15">
        <div class="col-12 col-md-auto mb-2">
            <div class="footer-logo"> <img src="@/assets/images/logo_EFX_TM_dark.png" alt="Equifax stock ticker logo">
            </div>
        </div>
        <div class="d-flex flex p-2 m-auto justify-content-xl-center">
            <nav class="footer-links nav flex-md-nowrap fs12"> <a class="nav-link" href="https://www.equifax.com/privacy">Privacy
                    Policy</a> <a class="nav-link" href="https://www.equifax.com/terms/">Terms of Use</a> <a class="nav-link"
                    href="https://www.equifax.com/personal/accessibility-statement/">Accessibility</a> </nav>
        </div>
        <div class="d-flex flex p-2 mr-auto justify-content-xl-end">
            <div class=""> <img src="@/assets/images/english_185x10_dark.png" class="img-fluid"
                    alt="Equifax English Tagline" title="Powering the World with Knowledge"> </div>
        </div>
    </div>
    <div class="d-flex flex align-items-center pl-15 pr-15 pb-3">
        <div class="d-flex flex p-2 ml-auto">
            <div class="copyright-info fs12 pl-3 pl-xl-0">
                <p>Copyright 2022 Equifax, Inc. All rights reserved.</p>
                <p>Equifax and the Equifax marks used herein are trademarks of Equifax Inc. Other product and company
                    names mentioned herein are the property of their respective owners. </p>
            </div>
        </div>
    </div>
</footer>
</template>

<style>
.content-footer {
  margin-top: auto;
  width: 100%;
}</style>