import apiUrl from '../assets/api-links.json'
import Axios, { AxiosInstance } from 'axios';
import { SNOWInteraction, JIRARequest, SNOWCourtOps, RecaptchaRequest } from '../types/request';

type TypeKey = 'development' | 'production'
type Type={ [key in TypeKey]: string}
const types: Type = apiUrl
const env_url: TypeKey = process.env.NODE_ENV
const url = types[env_url]

export const webhookGet = (): AxiosInstance => Axios.create({
  baseURL: url,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  withCredentials: false
})


export const webhookRequest = (body: SNOWInteraction | JIRARequest | SNOWCourtOps | RecaptchaRequest ): AxiosInstance => Axios.create({
  baseURL: url,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  withCredentials: false,
  data: JSON.stringify(body)
})

export const attachmentRequest = (number: string, type: string, form: FormData): AxiosInstance => Axios.create({
    baseURL: url,
    headers: {
        'Content-Type': type,
        'Access-Control-Allow-Origin': '*'
    },
    data: form
})

export const ipRequest = (): AxiosInstance => Axios.create({
  baseURL: "https://api.ipify.org/"
})
