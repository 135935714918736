import { createApp } from 'vue'
import { VueReCaptcha } from "vue-recaptcha-v3";
import App from '@/App.vue'
import router from './router'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { IReCaptchaOptions } from 'vue-recaptcha-v3/dist/IReCaptchaOptions';

createApp(App)
    .use(router)
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITEKEY } as IReCaptchaOptions)
    .use(Toast)
    .mount('#app')
