import { IPAddress, JIRARequest, RecaptchaRequest, SNOWCourtOps } from '@/src/types/request'
import { JIRAResponse, SNOWResponse, GenericResponse, Data, Value, RecaptchaResponse } from '@/src/types/response'
import { webhookRequest, attachmentRequest, webhookGet, ipRequest } from '../services/index'
import { SNOWInteraction } from '@/src/types/request'

export async function postSNOW(body: SNOWInteraction | SNOWCourtOps, type: string): Promise<SNOWResponse> {
    return (await webhookRequest(body).post<SNOWResponse>(`/servicenow/create?ticket_type=${type}`, body)).data
}

export async function postJIRARequest (payload: JIRARequest): Promise<JIRAResponse> {
    return (await webhookRequest(payload).post<JIRAResponse>('/jira/create', payload)).data
}

export async function uploadFile (number: string, type: string, form: FormData, table: string): Promise<GenericResponse> {
    return (await attachmentRequest(number, type, form).post<GenericResponse>(`/servicenow/file_upload?number=${number}&table=${table}`, form)).data
}

export async function getSNOWData (uri: string, product: string): Promise<Data[]|Value[]> {
    const p = {
        "uri": uri
    } as any
    if(product == "") {
        const params = new URLSearchParams(p);
        return (await webhookGet().get<Data[]>("/servicenow/component", { params })).data
    } else {
        p["product"] = product;
        const params = new URLSearchParams(p);
        return (await webhookGet().get<Data[]>("/servicenow/component", { params })).data
    }
    
}

export async function getIPAddress(): Promise<IPAddress> {
    return (await ipRequest().get("?format=json")).data
}

export async function validateRecaptcha(payload: RecaptchaRequest): Promise<RecaptchaResponse> {
    return (await webhookRequest(payload).post<RecaptchaResponse>("/google/verify_recaptcha", payload)).data
}